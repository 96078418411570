import { MuiThemeProvider } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import { saveAs } from 'file-saver';
import debounce from 'lodash/debounce';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TOOLTIP_DISABLED } from '@yojee/helpers/constants';
import { DialogConfirm, LinkTable, ListManageTemplate, theme, useNotistack, useTable } from '@yojee/ui/base';
import { ReadOnlyContext } from '@yojee/ui/ReadOnlyHelper';

import { UIAuthActions } from '../../sagas/auth/actions';
import { UIUserManagementActions } from '../../sagas/userManagement/actions';
import ModifyRoleDialog from './sub/modify/ModifyRoleDialog';
import ModifyRoleUsersDialog from './sub/modify/ModifyRoleUsersDialog';

const headCells = [
  { id: 'id', numeric: true, label: <Trans>ID</Trans>, width: '10%' },
  { id: 'role_name', numeric: true, label: <Trans>Name</Trans>, width: '10%' },
  { id: 'company_name', numeric: false, label: <Trans>Company</Trans>, width: '20%' },
  { id: 'numberOfUsers', numeric: false, label: <Trans>Number Of Users</Trans>, width: '20%' },
  { id: 'organisationalUnits', numeric: false, label: <Trans>OUs</Trans>, width: '20%' },
  { id: 'description', numeric: false, label: <Trans>Description</Trans>, width: '20%' },
];

export const Roles = ({ openDialog, bulkUpdateService }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const readOnly = useContext(ReadOnlyContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isRoleUsersDialogOpen, setIsRoleUsersDialogOpen] = useState(false);
  const [isOpenConfirmDeletionDialog, setIsOpenConfirmDeletionDialog] = useState(false);
  const [currentRole, setCurrentRole] = useState(undefined);
  const [searchText, setSearchText] = useState('');
  const successMessage = useSelector((state) => state.users.successMessage);
  const errorMessage = useSelector((state) => state.users.errorMessage);
  const roles = useSelector((state) => state.users?.roles?.list ?? []);
  const pagination = useSelector((state) => state.users?.roles?.pagination ?? {});
  const relatedCompanies = useSelector((state) => state.auth?.relatedCompanies ?? []);
  const inProgress = useSelector((state) => state.users?.inProgress);
  const { current_page, limit_value } = pagination;
  const {
    company: { id: currentCompanyId } = {},
    id: currentDispatcherId,
    related_company_ids: allowedCompanyIds = [],
  } = useSelector((state) => state.auth?.dispatcher_info?.data ?? {});

  const rows = roles?.map((row) => {
    const {
      id,
      name,
      company_id,
      dispatchers,
      description,
      permissions,
      organisational_units,
      assigned_to_blank_ou,
      assigned_to_all_ous,
    } = row;
    const _company = relatedCompanies.find((c) => c.company_id === row.company_id) || {};

    return {
      id,
      role_name: name,
      company_name: `${_company.company_name} - ${_company.company_id}`,
      numberOfUsers: (
        <LinkTable
          onClick={(e) => {
            e.stopPropagation();
            setIsRoleUsersDialogOpen(true);
            setCurrentRole(row);
          }}
        >
          {dispatchers?.length}
        </LinkTable>
      ),
      organisationalUnits: assigned_to_all_ous ? (
        <Trans>All OUs</Trans>
      ) : !organisational_units || organisational_units.length === 0 ? (
        '-'
      ) : (
        organisational_units.map((ou) => ou.name).join(', ')
      ),
      description,
      permissions,
      organisational_units,
      company_id,
      name,
      dispatchers,
      assignedToBlankOU: assigned_to_blank_ou,
      assignedToAllOUs: assigned_to_all_ous,
    };
  });

  useNotistack({
    successMessage,
    errorMessage,
    onClose: () => dispatch(UIUserManagementActions.clearMessages()),
  });

  const { selected, isSelected, setSelected, handleClick, handleSelectAllClick, DEBOUNCE_SEARCH_TIME } = useTable({
    rows,
    data: roles,
  });
  const primaryBtns = [
    {
      label: <Trans>Create Role</Trans>,
      color: 'primary',
      onClick: () => {
        setIsDialogOpen(true);
        setCurrentRole(undefined);
      },
      disabled: readOnly,
      tooltip: readOnly ? TOOLTIP_DISABLED : '',
    },
  ];

  // when add more actions to this, remember there's a readOnly mode
  const actionBtns = [
    {
      label: <Trans>Download</Trans>,
      onClick: () => {
        bulkUpdateService.downloadMasterData({ ids: selected, type: 'role' }).then((data) => {
          const url = URL.createObjectURL(new Blob([data], { type: 'data:text/csv;charset=utf-8' }));
          saveAs(url, `role_data_${selected.length}.csv`);
        });
      },
      disabled: false,
    },
  ];

  const actionBtnsShow = selected?.length > 0;

  const isRoleBelongsToCurrentDispatcher = (_role) => {
    const { company_id, dispatchers } = _role;
    if (company_id !== currentCompanyId || !dispatchers.length) {
      return false;
    }

    return dispatchers.findIndex((dpc) => dpc.id === currentDispatcherId) > -1;
  };

  const _loadRole = (page, q = null) => {
    dispatch(
      UIUserManagementActions.requestGetRoles({
        page: page?.page || current_page,
        page_size: page?.page_size || limit_value,
        q: q === null ? searchText : q,
      })
    );
  };

  const searchQuery = debounce((q) => {
    _loadRole({ page: 1, page_size: pagination.page_size }, q);
  }, DEBOUNCE_SEARCH_TIME);

  const searchInput = {
    icon: <SearchIcon />,
    onChange: (e) => {
      setSearchText(e.target.value);
      searchQuery(e.target.value);
    },
    value: searchText,
  };

  useEffect(() => {
    if (openDialog === false && isDialogOpen === true) {
      setIsDialogOpen(false);
    }
  }, [openDialog]);

  useEffect(() => {
    _loadRole({
      page: 1,
      page_size: 25,
    });
    dispatch(UIUserManagementActions.requestGetEnterprisePermissions());
    dispatch(UIAuthActions.loadRelatedCompanies());
    dispatch(UIAuthActions.loadDispatcherInfo());
  }, []);

  const tableConfig = {
    id: 'YJTableAllDrivers',
    numSelected: selected.length,
    rowCount: rows?.length,
    columns: headCells,
    data: rows,
    showCheckBox: true,
    isSelected: isSelected,
    onRowClick: handleClick,
    onSelectAllClick: handleSelectAllClick,
    rowActions: readOnly
      ? null
      : [
          {
            type: 'edit',
            title: <Trans>Edit</Trans>,
            icon: <EditIcon color="primary" fontSize="small" />,
            onClick: (row) => {
              setCurrentRole(row);
              setIsDialogOpen(true);
            },
            conditions: (row) => allowedCompanyIds.includes(row.company_id),
          },
          {
            type: 'delete',
            title: <Trans>Delete</Trans>,
            icon: <DeleteIcon color="primary" fontSize="small" />,
            onClick: (row) => {
              setCurrentRole(row);
              setIsOpenConfirmDeletionDialog(true);
            },
            conditions: (row) => {
              if (!allowedCompanyIds.includes(row.company_id)) return false;

              return !isRoleBelongsToCurrentDispatcher(row) && row.name?.toLowerCase() !== 'admin';
            },
          },
        ],
  };

  const renderDialogInside = () => {
    return (
      <>
        {isDialogOpen && (
          <ModifyRoleDialog
            className="modify-user-dialog-container"
            key="modify-role-dialog"
            open={isDialogOpen}
            maxWidth="xs"
            allowedCompanyIds={allowedCompanyIds}
            role={currentRole}
            onSave={(roleId, role) => {
              const _company = relatedCompanies.find((c) => c.company_id === role.company_id) || {};
              if (!roleId) {
                dispatch(
                  UIUserManagementActions.requestCreateRole(
                    { role, slug: _company.company_slug },
                    {
                      page: current_page,
                      page_size: limit_value,
                      q: searchText,
                    }
                  )
                );
              } else {
                dispatch(
                  UIUserManagementActions.requestUpdateRole(
                    { roleId, data: role, slug: _company.company_slug },
                    {
                      page: current_page,
                      page_size: limit_value,
                      q: searchText,
                    }
                  )
                );
              }
            }}
            onClose={() => {
              setIsDialogOpen(false);
              setCurrentRole(undefined);
            }}
          />
        )}
        {isRoleUsersDialogOpen && (
          <ModifyRoleUsersDialog
            currentDispatcherId={currentDispatcherId}
            className="modify-user-dialog-container"
            key="modify-permissions-dialog"
            open={isRoleUsersDialogOpen}
            maxWidth="xs"
            disabled={!allowedCompanyIds.includes(currentRole?.company_id)}
            role={currentRole}
            readOnly={readOnly}
            onSave={(roleId, userIds) => {
              const _company = relatedCompanies.find((c) => c.company_id === currentRole.company_id) || {};
              dispatch(
                UIUserManagementActions.requestUpdateRole({
                  roleId,
                  data: { user_profile_ids: userIds },
                  slug: _company.company_slug,
                })
              );
            }}
            onClose={() => {
              setIsRoleUsersDialogOpen(false);
              setCurrentRole(undefined);
            }}
          />
        )}
        {isOpenConfirmDeletionDialog && (
          <DialogConfirm
            key="conform-deletion-dialog"
            className="conform-deletion-dialog"
            open={isOpenConfirmDeletionDialog}
            title={<Trans>Delete Role?</Trans>}
            onClose={() => {
              setIsOpenConfirmDeletionDialog(false);
              setCurrentRole(undefined);
            }}
            onSave={() => {
              setIsOpenConfirmDeletionDialog(false);
              const _company = relatedCompanies.find((c) => c.company_id === currentRole.company_id) || {};
              dispatch(
                UIUserManagementActions.requestDeleteRole(
                  { roleId: currentRole.id, slug: _company.company_slug },
                  {
                    page: current_page,
                    page_size: limit_value,
                    q: searchText,
                  }
                )
              );
              setCurrentRole(undefined);
              setSelected([]);
            }}
            uploadItemType="user"
            dividers="paper"
          >
            <Trans>
              Are you sure you want to delete this role? Please check, because this action cannot be undone.
            </Trans>
          </DialogConfirm>
        )}
      </>
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <ListManageTemplate
        title={t('Roles')}
        primaryBtns={primaryBtns}
        actionBtnsShow={actionBtnsShow}
        actionBtns={actionBtns}
        tableConfig={tableConfig}
        searchInput={searchInput}
        loadingConfig={{ pageLoading: false, tableLoading: inProgress.requestGetRoles }}
        pagination={{
          pagination: pagination,
          onChangePage: _loadRole,
          onChangeRowsPerPage: _loadRole,
        }}
      />
      {renderDialogInside()}
    </MuiThemeProvider>
  );
};
