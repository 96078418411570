import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';

import { organisationalServiceApi } from '@yojee/api/v4/organisationalUnitApi';
import { OrganisationalUnit } from '@yojee/types';
import TransferList from '@yojee/ui/base/corners/TransferSelect';

type OrganisationalUnitItem = {
  value: number;
  label: string;
};

type Props = {
  value: OrganisationalUnitItem[];
  onChange: (newValue: OrganisationalUnitItem[]) => void;
  missingOUError: boolean;
};

export default function OrganisationalUnitMultiSelect({ value, onChange, missingOUError }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [availableItems, setAvailableItems] = useState<OrganisationalUnitItem[]>([]);
  const transferListBottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchOrganisationalUnits = async () => {
      setIsLoading(true);

      try {
        const { data: organisationalUnits }: { data: OrganisationalUnit[] } =
          await organisationalServiceApi.fetchOrganisationalUnits({
            q: '',
            page: 1,
            page_size: 500,
          });

        setAvailableItems(
          organisationalUnits
            .filter(({ id }) => !value.find((item) => item.value === id))
            .map(({ id, name }) => ({ value: id, label: name }))
        );
      } catch (error) {
        // DO NOTHING
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganisationalUnits();
  }, []);

  useEffect(() => {
    if (!isLoading && transferListBottomRef.current) {
      transferListBottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLoading]);

  return isLoading ? (
    <Box height={128} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress size={24} />
    </Box>
  ) : (
    <React.Fragment>
      <TransferList
        leftTitle={t('Available Organisational Units')}
        rightTitle={t('Selected Organisational Units')}
        leftItems={availableItems}
        rightItems={value}
        setLeftItems={(items: OrganisationalUnitItem[]) => setAvailableItems(items)}
        setRightItems={onChange}
        leftRequired={false}
        rightRequired={false}
        rightError={missingOUError}
        rightHelperText={missingOUError ? t('Please select at least one OU or check Blank OU') : ''}
      />
      <div ref={transferListBottomRef} />
    </React.Fragment>
  );
}
